import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import find from 'lodash-es/find';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { SurchargeAction } from './surcharge.action';
import { SurchargeApi } from './surcharge.api';
import { SurchargeModel, SurchargeState } from './surcharge.model';

@Injectable()
export class SurchargeService {
  constructor(
    private store: Store<AppState>,
    private api: SurchargeApi,
  ) {}

  load() {
    this.store.dispatch(SurchargeAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(SurchargeAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(SurchargeAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  add(surchargeData): Observable<any> {
    this.store.dispatch(SurchargeAction.add(surchargeData));

    return this.api.add(surchargeData).pipe(
      map((response) => {
        this.store.dispatch(SurchargeAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(SurchargeAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id, surchargeData) {
    this.store.dispatch(SurchargeAction.update(surchargeData));

    return this.api.update(id, surchargeData).pipe(
      map((response) => {
        this.store.dispatch(SurchargeAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(SurchargeAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetch(id) {
    this.store.dispatch(SurchargeAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(SurchargeAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(SurchargeAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  save(surchargeData) {
    if (surchargeData.id) {
      return this.update(surchargeData.id, surchargeData);
    }

    surchargeData = u.omit('id', surchargeData);

    return this.add(surchargeData);
  }
}

export const sortSurcharges = (surcharges: SurchargeModel[]) =>
  sortBy(surcharges, (surcharge) => parseInt(surcharge.surcharge, 10));
export const mapAndSortSurcharges = mapAndSortEntities(sortSurcharges);

export const getSurchargeState = (appState: AppState): SurchargeState => appState.orm.surcharges;

export const getSurchargeIds = compose((state) => state.items, getSurchargeState);
export const getSurchargeEntities = compose((state) => state.itemsById, getSurchargeState);
export const getSurcharges = createSelector(getSurchargeIds, getSurchargeEntities, mapAndSortSurcharges);

export const getSurcharge = (id: string) => createSelector(getSurchargeEntities, (entities) => mapEntity(id, entities));

export const getDefaultSurcharge = createSelector(getSurchargeEntities, (entities) =>
  find(entities, (entity) => entity.surcharge === '100.00'),
);
