import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Surcharge]';

export const surchargeActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,
};

export class SurchargeAction {
  static load(): Action {
    return {
      type: surchargeActionType.LOAD,
    };
  }

  static loadSuccess(surcharge): Action {
    return {
      type: surchargeActionType.LOAD_SUCCESS,
      payload: surcharge,
    };
  }

  static loadFailed(err): Action {
    return {
      type: surchargeActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(surchargeData): Action {
    return {
      type: surchargeActionType.ADD,
      payload: surchargeData,
    };
  }

  static addSuccess(surchargeResponse): Action {
    return {
      type: surchargeActionType.ADD_SUCCESS,
      payload: surchargeResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: surchargeActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(surchargeData): Action {
    return {
      type: surchargeActionType.UPDATE,
      payload: surchargeData,
    };
  }

  static updateSuccess(surchargeResponse): Action {
    return {
      type: surchargeActionType.UPDATE_SUCCESS,
      payload: surchargeResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: surchargeActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: surchargeActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: surchargeActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: surchargeActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }
}
